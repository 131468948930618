<template>
    <div class="box">
        <div class="login">
            <div class="title">管理登录</div>
            <el-form ref="loginForm" :model="loginForm" label-position="left">
                <el-form-item prop="zh" label="账号:">
                    <el-input v-model="index" placeholder="请输入" tabindex="2" />
                </el-form-item>
                <el-form-item prop="password" label="密码:">
                    <el-input v-model="index1" placeholder="请输入" show-password />
                </el-form-item>
            </el-form>
            <el-button type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">
                登录
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'science_child2',
    data() {
        return {
            loginForm: {},
            index: '',
            index1: '',
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        handleLogin() {
            if (this.index == 'admin' && this.index1 == '123456') {
                this.$router.push('/culture')
                this.$message({
                    message: '登录成功！',
                    type: 'success'
                });
            } else {
                this.$message({
                    message: '账号密码错误！',
                    type: 'warning'
                });
            }
        },
    }
}
</script>
<style lang="less" scoped>
.el-form-item__label {
    color: #fff !important;
}

.title {
    font-size: 26px;
    color: #eee;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
}

.box {
    min-height: 100%;
    width: 100%;
    height: 100vh;
    background-color: #2d3a4b;
    overflow: hidden;
}

.login {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
}
</style>